import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { Image } from '../../components/LazyImage';
import { Typography } from '../../components/DesignSystemV2/Typography';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { colorsV2 } from '../../style/colors-v2';
import { fromScreen } from '../../utils/media-query/responsive.util';
import { ResponsiveLayout } from 'shared/Layout/ResponsiveLayout';
import { HeadlineIcon } from 'components/HeadlineIcon';
import ButtonText from 'components/ButtonText';
import { withHomePageSection } from 'components/withHomePageSection';
import Button from 'components/Button';

const NewsCardWrapper = styled.div`
  display: grid;
  grid-template-rows: 124px max-content 1fr max-content;
  height: 100%;
  padding: 24px;
  border: 1px solid #d2d3d9;
  border-radius: 8px;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .description {
    color: ${colorsV2.black80};
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 200px;
  }

  .link,
  .link > * {
    margin-top: 24px;
  }

  ${fromScreen(1366)} {
    padding: 24px 32px;
  }
`;
const NewsCard = ({
  logoHandle,
  title,
  description,
  actionText,
  actionUrl
}) => {
  return (
    <NewsCardWrapper>
      <div className="logo">
        <Image handle={logoHandle} alt={title} />
      </div>
      <Typography
        className="description"
        variant="regular/14"
        dangerouslySetInnerHTML={{ __html: description }}
      ></Typography>
      <div className="link">
        <Link
          rel="noopener noreferrer nofollow"
          target="_blank"
          href={actionUrl}
        >
          <ButtonText content={actionText} type="primary" />
        </Link>
      </div>
    </NewsCardWrapper>
  );
};

const NewsLogo = ({ logoHandle, selected, setActive, index, title }) => {
  return (
    <NewsLogoWrapper
      onClick={setActive}
      className={cl({ active: index === selected })}
    >
      <Image handle={logoHandle} alt={title} />
    </NewsLogoWrapper>
  );
};

const NewsAboutDol = styled.div``;
const NewsAboutDolWrapper = styled.div`
  display: grid;
  gap: 24px;
  .link {
    text-align: center;
    button {
      width: fit-content;
      padding: 10px 16px;
      border-radius: 8px;
    }
  }

  ${fromScreen(776)} {
    gap: 40px;
  }
  h2 {
    margin-bottom: 40px;
  }
`;

const Magazine = styled.div`
  display: none;
  ${fromScreen(1144)} {
    display: block;
    position: relative;
    padding: 0 93px;

    .img-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${colorsV2.gray10};
      border-radius: 50%;
      height: 412px;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 406px 1fr;
  align-items: center;
  gap: 75px;
`;

const NewsAboutDolTablet = styled.div`
  display: grid;
  gap: 24px;

  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
  }
`;

const NewsLogoWrapper = styled.div`
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${colorsV2.gray20};
  background: ${colorsV2.white100};
  &:hover {
    border: 1px solid ${colorsV2.primary100};
    background: ${colorsV2.primary10};
  }

  line-height: 0;
  &.active {
    border: 1px solid ${colorsV2.primary100};
    background: ${colorsV2.primary10};
  }
  width: 164px;
  text-align: center;
  position: absolute;
  > img {
    height: 28px;
  }

  &:nth-child(2) {
    top: 40px;
    right: 0;
  }
  &:nth-child(3) {
    right: 0;
    top: 50%;
  }
  &:nth-child(4) {
    bottom: 0;
    right: 50px;
  }
  &:nth-child(5) {
    bottom: 50px;
    left: 0;
  }
  &:nth-child(6) {
    bottom: 60%;
    left: 0;
  }
  &:nth-child(7) {
    top: 0;
    left: 100px;
  }
`;
export const NewsAboutDolV2 = ({ news = [] }) => {
  const [selected, setSelected] = React.useState(0);
  return (
    <NewsAboutDolWrapper>
      <HeadlineIcon iconHandle="RY1uavGWQqW2aZ0GGJDo" isHomePage>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Báo chí nói gì về DOL
        </Typography>
      </HeadlineIcon>
      <ResponsiveLayout
        breakpointWithComponents={[
          {
            breakpoint: 775,
            ssrDefault: true,
            renderComponent: (
              <>
                <NewsAboutDolTablet>
                  {news?.slice(0, 3).map((item, index) => (
                    <NewsCard {...item} key={index} />
                  ))}
                </NewsAboutDolTablet>
                {/* <Link to="/bao-chi" className="link">
                  <Button outline>
                    <Typography
                      style={{ color: colorsV2.primary100 }}
                      variant="semi-bold/14-24"
                    >
                      Xem báo chí nói về DOL
                    </Typography>
                  </Button>
                </Link> */}
              </>
            )
          },
          {
            breakpoint: 1143,
            ssrDefault: true,
            renderComponent: (
              <NewsAboutDolTablet>
                {news?.map((item, index) => (
                  <NewsCard {...item} key={index} />
                ))}
              </NewsAboutDolTablet>
            )
          },
          {
            breakpoint: 1144,
            renderComponent: (
              <Content>
                <NewsAboutDol>
                  {news?.map(
                    (item, index) =>
                      selected === index && <NewsCard {...item} key={index} />
                  )}
                </NewsAboutDol>
                <Magazine>
                  <div className="img-bg">
                    <Image
                      handle="26U9g2wBSgOCgEseBLqA"
                      alt="Báo chí nói về DOL"
                    />
                  </div>
                  {news?.map((item, index) => (
                    <NewsLogo
                      {...item}
                      key={index}
                      index={index}
                      selected={selected}
                      setActive={() => setSelected(index)}
                    />
                  ))}
                </Magazine>
              </Content>
            )
          }
        ]}
      />
    </NewsAboutDolWrapper>
  );
};

export const GCMSNewsAboutDolV2 = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "LandingCourse_NewsAboutDol_V2" }) {
            content
          }
        }
      }
    `);

    return <NewsAboutDolV2 news={data.gcms.jsonContanier.content} />;
  },
  {},
  {}
);
