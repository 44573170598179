import * as React from 'react';

import { GCMSCourses } from 'shared/CourseLanding/CoursesHomePage';
import { GCMSDifferencesHome } from 'shared/CourseLanding/DifferencesHome';
import { GCMSHeader } from 'shared/CourseLanding/HeaderV2';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import styled from 'styled-components';
import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';

import { fromScreen } from 'utils/media-query/responsive.util';

import { GCMSNewsAboutDolV2 } from '../shared/CourseLanding/NewsAboutDolV2';
import AllDolTeachersV2 from 'shared/DolTeachers/AllDolTeachersV2';
import { CTAForScheduleAndRegister } from '../shared/DolBigCTA/cta-schedule-register';
import StudentReviews from '../shared/StudentCourseLanding/StudentReviewsV2';
import HallOfFame from 'shared/HallOfFameV2';

const CourseLandingPage = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        homePage
        slug={location.pathname}
        hidePostfix
        featureImg="5XDGAP2PTbqRvAjJp05G"
        title="Trung tâm luyện thi IELTS TOP 1 TP HCM - DOL English"
        description="DOL English IELTS Đình Lực cung cấp các khoá học IELTS với hệ phương pháp Linearthinking, đội ngũ giáo viên tận tâm và nền tảng công nghệ độc quyền. Đảm bảo đầu ra và trung bình giúp học viên tăng 1.0 đến 1.5 band sau 2 tháng."
      />
      <FixedTopBarScholarshipScheduleNOffline />
      <GCMSHeader />
      <GCMSCourses />
      <Space>
        <GCMSDifferencesHome />
        <HallOfFame />
        <StudentReviews
          program="all"
          columns={{ default: 3, 1143: 2, 776: 1 }}
        />
        <AllDolTeachersV2 />
        <GCMSNewsAboutDolV2 />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  > section {
    padding: 32px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
`;

export default React.memo(CourseLandingPage);
